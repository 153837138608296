import store from "store/reducers/index";
import api from "./index";

export function login(token){
	window.localStorage.setItem("plena:autor", token);
	init();
}
export function init(){
	
	const token = window.localStorage.getItem("plena:autor");
	console.log(token);
	if(token){
		api.init(token);


		store.dispatch({type:"SET_SESSION",payload:{logged:true}});
		const loadData = [
			"COURSE",
			"CATEGORY",
			"AUTHOR",
			"CLASS",
			"DOCUMENT",
			"TASK",
			"COMMENT",
		];
		loadData.map((key)=>store.dispatch({ type: `LOAD_${key}` }));
	
	}
}
export function refresh(key){
	if(key){
		store.dispatch({ type: `LOAD_${key.name}`, payload:{
			refresh: true
		} });
		return;
	}
	const refreshData = [
		"COURSE",
		"CATEGORY",
		"AUTHOR",
		"CLASS",
		"DOCUMENT",
		"TASK",
		"COMMENT",
	];
	refreshData.map((key)=>store.dispatch({ type: `LOAD_${key}`, payload:{
		refresh: true
	} }));
}
export function getNutitionistId(){
	
	const token = window.localStorage.getItem("plena:autor");
	if(token){
		const jwtData = parseJwt(token);
		return jwtData.id_nutriologo;
	}else{
		return -1;
	}
}
export function parseJwt (token) {
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
		return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(""));

	return JSON.parse(jsonPayload);
}

export default {login,init, parseJwt};