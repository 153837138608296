import rootSaga from "../sagas/index";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import crudReducer from "./crudReducer";
import {
	AUTHOR,
	CLASSES,
	COURSE,
	TASK,
	DOCUMENT,
	CATEGORY,
	COMMENT,
} from "../constants";

var emptyReducer = () => ({});


const authors = crudReducer(emptyReducer, AUTHOR.name, {
	data: [],
	view: {}
});

const classes = crudReducer(emptyReducer, CLASSES.name, {
	data: [],
	view: {}
});
const courses = crudReducer(emptyReducer, COURSE.name, {
	data: [],
	view: {}
});

const tasks = crudReducer(emptyReducer, TASK.name, {
	data: [],
	view: {}
});
const documents = crudReducer(emptyReducer, DOCUMENT.name, {
	data: [],
	view: {}
});
const categories = crudReducer(emptyReducer, CATEGORY.name, {
	data: [],
	view: {}
});
const comments = crudReducer(emptyReducer, COMMENT.name, {
	data: [],
	view: {}
});


const sagaMiddleware = createSagaMiddleware();

const app = combineReducers({
	[AUTHOR.name]:authors,
	[CLASSES.name]: classes,
	[COURSE.name]:courses,
	[TASK.name]:tasks,
	[DOCUMENT.name]: documents,
	[CATEGORY.name]:categories,
	[COMMENT.name]:comments,


});
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers =
//   typeof window === 'object' &&
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//       // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//     }) : compose;
const composerEnhancers = composeWithDevTools({
	name: "Redux",
	realtime: true,
	trace: true,
	traceLimit: 25
});
const enhancer = composerEnhancers(
	applyMiddleware(sagaMiddleware),
	// other store enhancers if any
);
const store = createStore(app, enhancer);

// var store = createStore(app, applyMiddleware(sagaMiddleware),window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
sagaMiddleware.run(rootSaga);

export default store;
