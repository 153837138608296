import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import { useSelector } from "react-redux";
import ComentarioModal from "./Comentario";
import { COMMENT, CLASSES, COURSE } from "store/constants";
import Header from "../../components/Header/Header";
import RefCell from "components/Table/CellTypes/RefCell/RefCell";
import BoolCell from "components/Table/CellTypes/BoolCell/BoolCell";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
// comentario: Joi.string(),
// 	id_usuario: Joi.string(),
// 	id_clase: Joi.string(),
// 	publicado: Joi.boolean().when('__insert', { is: true, then: Joi.boolean().default(false) }),


const TabTareas = () => {
	const data = useSelector(state => state[COMMENT.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Comentario",
				accessor: "comentario"
			},
			{
				Cell: RefCell,
				type:CLASSES,
				Header: "Clase",
				accessor: "id_clase"
			},
			{
				Cell: RefCell,
				type:COURSE,
				Header: "Curso",
				accessor: "id_curso"
			},
			{
				Header: "Leido",
				accessor: "leido",
				Cell:BoolCell
			},
			{
				Cell:DateCell,
				Header: "Fecha",
				accessor: "_created_date"
			}
		],
		[]
	);

	return (
		<>
			<Header title="Cursos" video time="5:35" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={COMMENT}
				modal={ComentarioModal}
				filters={columns}
				options={{ orderBy: true, disableDelete:true }}
			/>
		</>
	);
};

export default TabTareas;
