import React, {useState} from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import { Modal, Fade, Button, CircularProgress, IconButton } from "@material-ui/core";
import { COMMENT } from "store/constants";
import { useSelector } from "react-redux";
import useSave from "Hooks/useSave";
import AutoSaveStatus from "components/AutoSaveStatus/AutoSaveStatus";
import Header from "../../components/Header/Header";
import SaveModal from "components/SaveModal/SaveModal";
import RespuestaModal from "./Respuesta";
import styles from "Styles.module.scss";

const Categoria = props => {  
	const [openedModal, setOpenedModal] = useState(false);  
	const elements = [
		{
			type: "text",
			name: "comentario",
			label: "Comentario",
			readOnly:true
		},
		{
			type: "text",
			name: "_created_date",
			label: "Fecha",
			readOnly:true
		},
		{
			type: "bool",
			name: "leido",
			label: "Leido",
		},
	];
	// const sData = useSelector(state => state[AUTHOR.name].data);

	// const {data, onChange, onFile, remove, save, status, isValid, setValid} = useSave({type:AUTHOR,data:sData, autoSave:true, fileUrl:"foto"});
	// console.log({sData,data})

	return (
		<>		
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} onFile={props.onFile}>
				<Button onClick={()=>setOpenedModal(true)}>Responder</Button>

				<div>Respuestas</div>
				{props.data?.respuestas?.map(r=><div style={{borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'black'}} key={r._id}>
					{r.comentario}
				</div>)}
			</ModalForm>
			{openedModal && 	<Modal open={openedModal} className={styles.modal}>
				<Fade in={openedModal}>
					<div className={styles.paper} style={{width:props.fullWidth?"90%":""}}>
						<RespuestaModal dismiss={()=>setOpenedModal(false)} idComentario={props.data._id} />
					</div>
						
				</Fade>
			</Modal>}
				{/* // 		<SaveModal 
				// // {...props.options} 
				// fullWidth={false} 
				// // onInsert={(d)=>{
				// // 	setIsLoading(false);
				// // 	handleChange([...value, d]);
					
				// // }} 
				// modal={RespuestaModal} 
				// // forceNew={forceNew}
				// type={COMMENT} 
				// data={props.data}
				// // status={props.status} 
				// handleModal={()=>{
				// 	setOpenedModal(false);
				// }} /> */}
		</>
	);
};

export default Categoria;
