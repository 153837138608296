import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { COMMENT } from "../constants";

const loadComments = loadRequest(COMMENT);
const insertComment = insertRequest(COMMENT);
const updateComment = updateRequest(COMMENT);
const trashComment = trashRequest(COMMENT);
const untrashComment = untrashRequest(COMMENT);
const removeComment = removeRequest(COMMENT);

function* rootSaga() {
	yield all([
		loadComments(),
		insertComment(),
		updateComment(),
		removeComment(),
		trashComment(),
		untrashComment()
	]);
}
export default rootSaga;