import React from "react";
import { Tooltip } from "@material-ui/core";
import {Cancel, CheckCircle} from "@material-ui/icons";
const _ = require("lodash");

export default function BoolCell ({ cell }){
	return (
		<>
			<Tooltip title={cell.value && cell.column.labelOnTrue ? cell.column.labelOnTrue(cell) : cell.column.labelOnFalse && cell.column.labelOnFalse(cell)}>
				<div style={{display:"flex", justifyContent:"center"}}>
					{_.get(cell.row.original,cell.column.id) ? 
						<CheckCircle style={{color:"#a5dd72"}} size="small" alt="status-app" />
						: 
						<Cancel style={{color:"red"}} size="small" alt="status-app" />
					}
				</div>
			</Tooltip>
		</>
	);
}