import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import CursoModal from "./Curso";
import { useSelector } from "react-redux";
import { COURSE } from "store/constants";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";

const TabCursos = () => {
	const dispatch = useDispatch();
	const data = useSelector(state => state[COURSE.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre",
			}
		],
		[]
	);

	return (
		<>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={COURSE}
				modal={CursoModal}
				filters={columns}
				options={{ orderBy: true, disableInsert:true, disableDelete:true }}
				// canDelete={()=>false}
				actions={[({cell, setSelectedData, setOpenModal, setForceNew})=>{
					// console.log(cell, setData);
					const open = ()=>{
						// console.log(cell);
						dispatch({type:"UPDATE_COURSE",payload:{_id:cell.row.original._id, status:"edicion"}});
						// let {fecha, para, ...obj} = cell.row.original;

						// console.log(obj);
						// setForceNew(true);
						// setSelectedData(obj);
						// setOpenModal(true);
					};
					return (cell.row.original.status==="draft" && <Button onClick={open} variant="contained" style={{background:"#F8F8F8",color:"#3B4C4B", margin:"5px"}}>Enviar a edicion</Button>);
				}]}
			/>
		</>
	);
};

export default TabCursos;
