import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const Categoria = props => {    
	const elements = [
		{
			type: "text",
			name: "nombre",
			label: "Nombre del episodio",
		},
		{
			type: "text",
			name: "descripcion",
			label: "Descripción del episodio (explica a tu publico de que vas a hablar en esta sesión)",
		},
		{
			type: "bool",
			name: "gratis",
			label: "Gratis",
		},
		// {
		// 	type: "text",
		// 	name: "duracion",
		// 	label: "Duracion",
		// },
		{
			type: "select",
			name: "tipo",
			label: "Tipo",
			options:[
				{value:"video",label:"Video"},
				{value:"audio",label:"Audio"},
				{value:"pdf",label:"PDF"},
				{value:"texto",label:"Texto"}
			]
		},
		{
			type: "file",
			name: "archivos_sin_edicion",
			label: "Archivos sin edicion",
			accept:"video/*,audio/*,application/pdf,image/*",
			multipleFiles: true
		},	
		{
			type: "file",
			name: "archivo",
			label: "Archivo editado",
			accept:"video/*,audio/*,application/pdf",
		},
	];
	console.log(props);
	return (
		<>
			<ModalForm fileUrl="files" elements={elements} removeFile={props.removeFile} onChange={props.onChange} data={props.data} onFile={props.onFile}></ModalForm>
		</>
	);
};

export default Categoria;
