import React from "react";
import { Route } from "react-router-dom";

import "./Routes.scss";


import Home from "../pages/Home/Home";
import NavBar from "../pages/NavBar/NavBar";
import Cursos from "../pages/Cursos/index";
import Autor from "../pages/Autores/Autor";
import Comentarios from "../pages/Comentarios/TablaComentarios";
// import TablaAutores from "../pages/Autores/TablaAutores";
// import TablaCategorias from "../pages/Categorias/TablaCategorias";

import Login from "../pages/Login/Login";
import Registro from "../pages/Login/Registro";
import Forgot from "../pages/Login/Olvide";
import Reset from "../pages/Login/Reset";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import PublicRoute from "./PublicRoute/PublicRoute";

// import usePrevious from "Hooks/usePrevious";

const Routes = () => {
	// const prevPath = usePrevious(window.location.pathname);

	let RNavBar = window.location.pathname === "/login" || window.location.pathname === "/registro" || window.location.pathname.startsWith("/reset") || window.location.pathname.startsWith("/reset") || window.location.pathname === "/forgot" || window.location.origin === "/consultaonline" ? null : <NavBar />;
	return (
		<>
			<div className="container">
				<PublicRoute exact path="/login" component={Login} />
				<PublicRoute exact path="/registro" component={Registro} />
				<PublicRoute exact path="/forgot" component={Forgot} />
				<PublicRoute exact path="/reset/:token/:idNutriologo" component={Reset} />

				<div className={(window.location.pathname === "/login" || window.location.pathname === "/registro"||window.location.pathname === "/forgot") ? "": "container-app"}>
					{/* <Header /> */}
					{RNavBar}

					<PrivateRoute exact path="/" component={Home} />
					{/* <PrivateRoute path="/categorias" component={TablaCategorias} /> */}
					{/* <PrivateRoute path="/autores" component={TablaAutores} /> */}
					<PrivateRoute path="/cursos/:seccion?" component={Cursos} />
					<PrivateRoute path="/autores/" component={Autor} />
					<PrivateRoute path="/comentarios/" component={Comentarios} />

				</div>
			</div>
		</>
	);
};

export default Routes;
