import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import { CLASSES } from "store/constants";
import ClaseModal from "./Clase";

const Curso = props => {

	const elements = React.useMemo(()=>[
		
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		// {
		// 	type: "ref",
		// 	name: "id_cateogoria",
		// 	label: "Categoria",
		// 	selector:(state)=>state.CATEGORY.data.filter((r)=>!r._trash)

		// },
		{
			type: "text",
			name: "descripcion_corta",
			label: "Descripcion corta (20 a 40 caracteres que describan el curso)",
		},
		{
			type: "text",
			name: "descripcion",
			label: "Informacion (descripcion detallada del curso)",
			multiline: true
		},
		// {
		// 	type: "text",
		// 	subtype: "number",
		// 	name: "precio",
		// 	label: "Precio",
		// },
		{
			type: "file",
			name: "trailer",
			label: "Trailer",
			accept:"video/*",
		},
		// {
		// 	type: "file",
		// 	name: "imagen",
		// 	label: "Imagen",
		// },
		// {
		// 	type:"multiref",
		// 	name:"autores",
		// 	label:"Autores",
		// 	selector:(state)=>state.AUTHOR.data.filter((r)=>!r._trash)
		// },
		{
			type: "select",
			name: "idioma",
			label: "Idioma",
			options:[
				{value:"espanol",label:"Español"},
				{value:"ingles",label:"Inglés"}
			]
		},
		// {
		// 	type: "select",
		// 	name: "nivel",
		// 	label: "nivel",
		// 	options:[
		// 		{value:"basico",label:"Básico"},
		// 		{value:"intermedio",label:"Intermedio"},
		// 		{value:"Avanzado",label:"Avanzado"},
		// 	]
		// },
		{
			type: "multiText",
			name: "incluye",
			label: "Incluye - Que recibe tu publico al comprar el curso? (max 10 caracteres)",
			multiline: true

		},
		{
			type: "text",
			name: "publico",
			label: "Publico - A que publico esta dirigido el curso?",
			multiline: true

		},
		{
			type: "text",
			name: "requisitos",
			label: "Requisitos - Hay algun requisito para poder tomar el curso, pueden ser tecnicos como un horno o de conocimiento previo",
			multiline: true

		},
		{
			type: "multiGroup",
			name: "unidades",
			label: "Unidades - ",
			labelAddButton:"Agregar unidad",
			fields:[
				{
					type: "text",
					name: "nombre",
					label: "Nombre de unidad",
				},
				{
					type:"multiref",
					name:"clases",
					label:"Episodios de la unidad",
					selector:(state)=>state.CLASS.data.filter((r)=>!r._trash),
					onItemClick:(props)=>console.log("a",props),
					modal:ClaseModal,
					modalType:CLASSES
				},
				// {
				// 	type:"multiref",
				// 	name:"documentos",
				// 	label:"Documentos",
				// 	selector:(state)=>state.DOCUMENT.data.filter((r)=>!r._trash)
				// },
				// {
				// 	type:"multiref",
				// 	name:"tareas",
				// 	label:"Tareas",
				// 	selector:(state)=>state.TASK.data.filter((r)=>!r._trash)
				// },
			]
		}
	],[]);
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}></ModalForm>
		</>
	);
};

export default Curso;
