import React,{useState} from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import { Button } from "@material-ui/core";
import api from "api";
import Header from "../../components/Header/Header";
const Categoria = props => {  
	const elements = [
		{
			type: "text",
			name: "comentario",
			label: "Comentario",
		},

	];
	const [data,setData]=useState({});
	const onChange = (val, name)=>{
		console.log({[val]:name})
		setData(d=>({...d, [name]:val}));
	}

	const save = async() =>{
		await api.post(`/comments/${props.idComentario}`,data);
		props.dismiss();
		
	}


	return (
		<>		
			<ModalForm elements={elements} onChange={onChange} data={data} onFile={props.onFile}>
				<Button onClick={save}>Guardar</Button>
			</ModalForm>
		</>
	);
};

export default Categoria;
