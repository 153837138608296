import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import { AUTHOR } from "store/constants";
import { useSelector } from "react-redux";
import useSave from "Hooks/useSave";
import AutoSaveStatus from "components/AutoSaveStatus/AutoSaveStatus";
import Header from "../../components/Header/Header";

const Categoria = props => {    
	const elements = [
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "biografia",
			label: "Biografia",
			multiline:true
		},
		{
			type: "file",
			name: "foto",
			label: "Foto",
		},
		
		
	];
	const elements2 = [
		{
			type: "text",
			name: "datos_personales.nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "datos_personales.apellido_paterno",
			label: "Apellido paterno",
		},
		{
			type: "text",
			name: "datos_personales.apellido_materno",
			label: "Apellido materno",
		},
		{
			type: "text",
			name: "datos_personales.telefono",
			label: "Telefono",
		},
		{
			type: "text",
			name: "datos_personales.rfc",
			label: "RFC",
		},
		{
			type: "text",
			name: "datos_personales.cuenta_clabe",
			label: "Cuenta clabe",
		},
		{
			type: "text",
			name: "datos_personales.curp",
			label: "Curp",
		},
		{
			type: "text",
			name: "datos_personales.nombre_banco",
			label: "Nombre de banco",
		},
		{
			type: "text",
			name: "datos_personales.cp",
			label: "Codigo postal",
		},
	]
	const sData = useSelector(state => state[AUTHOR.name].data);

	const {data, onChange, onFile, remove, save, status, isValid, setValid} = useSave({type:AUTHOR,data:sData, autoSave:true, fileUrl:"foto"});
	console.log({sData,data})

	return (
		<>
			<Header title="Bio" video time="5:35" />
		
			<AutoSaveStatus status={status} save={save} />

			<ModalForm elements={elements} onChange={onChange} data={data} onFile={onFile}></ModalForm>
			<ModalForm elements={elements2} onChange={onChange} data={data} onFile={onFile}></ModalForm>
		</>
	);
};

export default Categoria;
