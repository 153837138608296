import { all } from "redux-saga/effects";

import authors from "./authors";
import categories from "./categories";
import classes from "./classes";
import courses from "./courses";
import documents from "./documents";
import tasks from "./tasks";
import comments from "./comments";
import {watchDownloadFileChannel} from "./generators";

// classes(),
function* rootSaga() {
	yield all([authors(), categories(), classes(),  courses(), documents(), tasks(), comments(), watchDownloadFileChannel()]);
}
export default rootSaga;
