import React,{useState} from "react";
import {Link, Grid, Hidden, Typography, Container, 
	TextField, CssBaseline, Button, CircularProgress, IconButton} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { useImmer } from "use-immer";
import {post} from "api";
import { useHistory, useRouteMatch } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function SignIn() {
	let match = useRouteMatch();


	const classes = useStyles();
	const [user, setUser] = useImmer({password:"",password2:""});
	const [status, setStatus] = useState({status:"ready"});
	const [valid, setValid] = useState(false);

	React.useEffect(()=>{
		setValid(user.password===user.password2);
	},[user]);
	const handleLogin = (e) => {
		e.preventDefault();
		
		setStatus({status:"loading"});
		post("nutritionist/reset/",{
			token: match.params.token,
			idNutriologo: match.params.idNutriologo,
			password: user.password
		}).then(()=>{
			setStatus({status:"success"});
		}).catch(e=>{
			console.log(e);

			setStatus({status:"error",message:e});
		});
		console.log(user);
	};
	return (
		<Grid container spacing={3}>

			<Grid item sm={12}>
				<Container component="main" maxWidth="xs">
					<CssBaseline />

					<div className={classes.paper}>
						<Button color="primary" href="/login" variant="body2" style={{ alignSelf: "flex-start"}}>
							<KeyboardBackspaceIcon />Volver al Login
						</Button>

						<img src={require("assets/img/logo/logo_nombre.png")}
							alt="imagen principal"
							style={{ 
								height: "100px",
								objectFit: "cover"}}
						/>
						<Typography color="primary" component="h1" variant="h5">
                Ingresa tu nueva contraseña
						</Typography>
						<form className={classes.form} noValidate>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Contraseña"
								type="password"
								id="password"
								autoComplete="current-password"
								value={user.password}
								onChange={(e)=>{
									e.persist();
									setUser((draft)=>{draft.password = e.target.value;});
								}}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Verificar contraseña"
								type="password"
								id="password"
								autoComplete="current-password"
								value={user.password2}
								onChange={(e)=>{
									e.persist();
									setUser((draft)=>{draft.password2 = e.target.value;});
								}}
							/>
							
							{status.status==="success" &&
                <Alert severity="success">
									<div>Contraseña reestablecida exitosamente</div>
									<Link href="/login" variant="body2">
										Ir a login
									</Link>
								</Alert>
							}
							{status.status==="error" &&
                  <Alert severity="error">{status.message}</Alert>
							}
							{status.status==="loading" ?
								<CircularProgress />
								:
								<Button
									type="submit"
									disabled={!valid}
									fullWidth
									variant="contained"
									color="primary"
									onClick={handleLogin}
								>
                  Resetear
								</Button>
							}

						</form>
					</div>
				</Container>
			</Grid>
		</Grid>
     
    
	);
}